<template>
  <div class="coupon">
    <!-- 优惠券 -->
    <div class="flexAndCenter couponHeader">
      <div>
        {{ status === 30 ? $fanyi("选择优惠券") : $fanyi("选择代金券") }}
      </div>
      <i class="el-icon-close" @click="$emit('cloneDialogVisible', false)"></i>
    </div>
    <div class="couponBody">
      <!-- 优惠卷 -->
      <div v-if="status === 30" class="couponListContainer">
        <!-- -->
        <div v-for="(item, index) in arrType" :key="index" :class="item.couponChecked
          ? 'couponActiveContainer flexAndCenter'
          : 'couponContainer flexAndCenter'
          ">
          <div class="couponLeft flexAndCenter">
            <div>Coupon</div>
          </div>
          <div class="couponRight">
            <div class="couponNumberText favourableText">
              <span>
                NO：{{ item.number }}
              </span>
              <el-checkbox v-model="item.couponChecked" @change="couponChange($event, item, 1)"></el-checkbox>
            </div>
            <div class="couponTitleText">{{ item.title }}</div>
            <div class="couponValidityText">
              {{ $fanyi("有效期") }}：{{ item.free_datetime_start }} —
              {{ item.free_datetime_end }}
            </div>
          </div>
        </div>
        <div v-if="arrType.length == 0">
          <div class="noyouhuijun" v-if="arrType.length == 0">
            <img src="../../../../../../assets/youhuijuan/voucher.svg" alt="" />
            <p>{{ $fanyi("暂时没有可使用的优惠券") }}</p>
          </div>
        </div>
      </div>

      <!-- 代金券 -->
      <div v-if="status !== 30" class="couponListContainer">
        <div v-for="(item, index) in arrData" :key="index" :class="item.couponChecked
          ? 'voucherActiveContainer flexAndCenter'
          : 'voucherContainer flexAndCenter'
          ">

          <div class="voucherLeft flexAndCenter">
            <div>Voucher</div>
          </div>
          <div class="couponRight">
            <div class="couponNumberText">
              <span>
                NO：{{ item.number }}
              </span>
              <el-checkbox v-model="item.couponChecked" @change="couponChange($event, item, 2)"></el-checkbox>
            </div>
            <div class="couponTitleText" style="color: #00c18b; margin-bottom: 10px; margin-top: 10px; height: 50px;">
              {{ item.title }}
            </div>
            <div style="margin-bottom: 15px" class="dingdanjine">
              {{ $fanyi("订单金额") }} >
              $ {{ item.restrictions }}
            </div>
            <div class="couponValidityText">
              {{ $fanyi("有效期") }}：{{ item.free_datetime_start }} —
              {{ item.free_datetime_end }}
            </div>
          </div>
        </div>
        <div class="noyouhuijun" v-if="arrData.length == 0">
          <img src="../../../../../../assets/youhuijuan/coupon.svg" alt="" />
          <p>{{ $fanyi("暂时没有可使用的代金券") }}</p>
        </div>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <div class="promptText">
        {{
          $fanyi(
            "温馨提示：一个订单只能使用一张优惠券，优惠券适用于订单、代金券适用于配送单"
          )
        }}
      </div>
      <div class="btnList flexAndCenter">
        <div @click.stop="getActiveCoupon">{{ $fanyi("确认") }}</div>
        <div @click="$emit('cloneDialogVisible', false)">
          {{ $fanyi("取消") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import termsService from "../../../../../footList/termsService";
export default {
  props: ["status", "porder_sn"],
  data() {
    return {
      form: {
        page: 1,
        pageSize: 9999999,
        pay: 1,
      },
      couponActiveIndex: null,
      active: {
        arrType: -1,
        arrData: -1,
      },
      datas: [],
      arrType: [],
      arrData: [],
      id: "",

    };
  },
  components: {},
  created() {
    this.getData();
  },
  methods: {
    couponChange(val, item, type) {
      item.couponChecked = val
      let arrList = []
      if (type == 1) {
        //优惠券
        arrList = this.arrType
      } else if (type == 2) {
        //代金券
        arrList = this.arrData
      }
      arrList.forEach((obj, ind) => {
        if (obj.id == item.id) {
        } else {
          obj.couponChecked = false
        }
      })
      this.$forceUpdate();
    },
    getActiveCoupon() {
      // status == 30代表是选择优惠券
      if (this.status === 30) {
        if (this.arrType.length > 0) {
          let flag = this.arrType.findIndex(item => item.couponChecked == true) != -1
          if (flag) {
            let targetObj = this.arrType.find(item => item.couponChecked == true)
            this.$emit(
              "getActiveCoupon",
              targetObj.id,
              true
            );
          } else {
            if (this.arrType.every((item) => item.couponChecked == false)) {
              this.$emit("getActiveCoupon", "", false);
            } else {
              this.$emit("getActiveCoupon", "", true);
            }
          }

        } else {
          this.$emit("getActiveCoupon", "");
        }
      } else {
        //选择代金券
        // 如果有代金券列表
        if (this.arrData.length > 0) {
          let flag = this.arrData.findIndex(item => item.couponChecked == true) != -1
          if (flag) {
            let targetObj = this.arrData.find(item => item.couponChecked == true)
            this.$emit(
              "getActiveCoupon",
              targetObj.id,
              true
            );
          } else {
            if (this.arrData.every((item) => item.couponChecked == false)) {
              this.$emit("getActiveCoupon", "", false);
            } else {
              this.$emit("getActiveCoupon", "", true);
            }
          }

        } else {
          this.$emit("getActiveCoupon", "");
        }
      }
    },
    //更新优惠券选中状态
    updateCouponActiveStatus(index, item) {

      item.checked = !item.checked;
      item.checked === true
        ? (this.couponActiveIndex = index)
        : (this.couponActiveIndex = null);

      this.arrType.forEach((arrTypeItem) => {
        if (arrTypeItem.id !== item.id) {
          arrTypeItem.checked = false;
        }
      });
    },
    //更新代金券选中状态
    updateVoucherActiveStatus(index, item) {
      item.checked = !item.checked;
      if (item.checked == true) {
        this.id = item.id;
      }
      item.checked === true
        ? (this.couponActiveIndex = index)
        : (this.couponActiveIndex = null);
      this.arrData.forEach((arrTypeItem) => {
        if (arrTypeItem.id !== item.id) {
          arrTypeItem.checked = false;
        }
      });
    },
    // 获取数据
    getData() {
      this.$api
        .couponGet(this.form)
        .then((res) => {
          this.data = res.data.data;
          this.lists = {};
          this.arrType = res.data.data.filter((item) => item.type != 30);

          this.arrType.forEach((item) => {
            item.free_datetime_start = item.free_datetime_start.substr(0, 10);
            item.free_datetime_end = item.free_datetime_end.substr(0, 10);
            item.checked = false;
          });
          this.arrData = res.data.data.filter((item) => item.type == 30);
          this.arrData.forEach((item) => {
            item.free_datetime_start = item.free_datetime_start.substr(0, 10);
            item.free_datetime_end = item.free_datetime_end.substr(0, 10);
            item.checked = false;
          });
          // this.$forceUpdate();
        })
        .catch((err) => { });

    },
  },
};
</script>

<style lang="scss" scoped>
.coupon {
  // background-color: pink;
  width: 480px;
  min-height: 220px;
  border-radius: 6px;
  background: #ffffff;

  .noyouhuijun {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    img {
      width: 128px;
      height: 128px;
    }

    p {
      margin-top: 10px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
    }
  }
}

.couponHeader {
  justify-content: space-between;
  //padding:0 10px;
  border-bottom: 1px solid #f5f5f5;
  height: 64px;
  margin: 0 10px;

  div:first-child {
    color: #222222;
    font-size: 18px;
    padding-left: 20px;
  }

  .el-icon-close {
    color: #000;
    font-size: 28px;
    font-weight: bold;
    margin-right: 20px;
  }
}

.couponBody {
  padding: 20px 20px 20px 30px;

  overflow: auto;
  max-height: 380px;

  // 代金券
  .couponListContainer {

    // flex-wrap: wrap;
    // display: flex;
    //'voucherActiveContainer flexAndCenter':'voucherContainer flexAndCenter'
    // 默认
    .couponContainer,
    .couponActiveContainer,
    .voucherContainer,
    .voucherActiveContainer {
      height: 180px;

      background: url("../../../../../../assets/youhuijuan/white.svg") no-repeat;
      // background-size: 100% 105%;
      // margin-bottom: 30px;
      margin: 0 !important;
      cursor: pointer;
      // background-color: yellow;

      .couponRight {
        height: 136px;



        .couponNumberText {
          width: 280px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #222;

          .el-checkbox {
            width: 20px;
            height: 20px;
          }

          ::v-deep .el-checkbox__inner {
            width: 20px;
            height: 20px;
            border-radius: 4px;
            border: 1px solid #CDCDCD;
          }

          .el-checkbox__input.is-focus .el-checkbox__inner {
            border-color: #CDCDCD;
          }

          ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
            background-color: #fff;
          }

          ::v-deep .el-checkbox__inner::after {
            height: 10px;
            width: 5px;
            left: 6px;
            border: 2px solid #00c18b;
            border-left: 0;
            border-top: 0;

          }

        }

        //优惠券选中样式
        .favourableText {
          ::v-deep .el-checkbox__inner::after {
            height: 10px;
            width: 5px;
            left: 6px;
            border: 2px solid #608BFF;
            border-left: 0;
            border-top: 0;

          }
        }

        .couponTitleText {
          color: #222222;
          font-weight: 600;
          width: 260px;
          height: 80px;
          display: flex;
          align-items: center;
          line-height: 31px;
          font-size: 30px;
          font-family: Roboto-Bold, Roboto;
          font-weight: bold;
          margin: 10px 0px;

          color: #608bff;
          font-size: 36px;
        }

        .couponValidityText {
          color: #222;
          font-size: 12px;
          // margin-top: 50px;
          margin-bottom: 10px;
        }
      }
    }

    .couponActiveContainer {
      background: url("../../../../../../assets/blue.svg") no-repeat !important;

      .couponRight {
        .couponNumberText {
          color: #fff !important;
        }

        .couponTitleText {
          color: #fff !important;
        }

        .couponValidityText {
          color: #fff !important;
        }
      }
    }

    .couponLeft {
      margin: 15px 20px 12px 22px !important;
      border-radius: 4px;
    }

    .voucherActiveContainer {
      background: url("../../../../../../assets/green.svg") no-repeat !important;

      .couponRight {
        height: 136px;

        .couponNumberText {
          color: #fff !important;

        }

        .couponTitleText {
          color: #fff !important;
        }

        .dingdanjine {
          color: #fff !important;
        }

        .couponValidityText {
          color: #fff !important;
        }
      }
    }

    .voucherLeft {
      border-radius: 4px;
      border: 2px solid #00c18b !important;

      div {
        color: #00c18b !important;
        min-width: 100px !important;
      }
    }

    .couponLeft,
    .voucherLeft {
      margin: 15px 20px 12px 22px;
      width: 64px;
      background: #ffffff;
      border: 2px solid #608bff;
      justify-content: center;
      height: 136px;

      div {
        color: #608bff;
        font-size: 24px;
        font-weight: 600;
        transform: rotateZ(270deg);
        min-height: 94px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 6px;
        min-width: 100px;
      }
    }

    .couponContainer:nth-child(2n),
    .couponActiveContainer:nth-child(2n),
    .voucherContainer,
    .voucherActiveContainer {
      margin-right: 0;
    }


  }
}

.dialog-footer {
  padding: 30px 30px 30px;

  // background-color: pink;
  .promptText {
    color: #999999;
    font-size: 12px;

    margin-bottom: 20px;
  }

  .btnList {
    display: flex;
    justify-content: center;

    div {
      width: 120px;
      height: 40px;
      background: #ff730b;
      line-height: 40px;
      cursor: pointer;
      text-align: center;
      border: 1px solid #e2e2e2;
      color: #fff;
      border-radius: 6px;
    }

    div:first-child {
      margin-right: 40px;
      border: 1px solid #ff730b;
    }

    div:last-child {
      color: #222222;
      background: #fff;
    }
  }
}
</style>
